import React, { Component } from "react"
import { Group, Col } from "./common"

class Blocks extends Component {
  static defaultProps = {
    blockData: [],
  }

  renderBlockData() {
    return this.props.blockData.map((block, index) => {
      return (
        <Col key={index}>
          <div
            className="block-container"
            style={{ ...styles.blockContainer, backgroundColor: block.color }}
          >
            <div style={styles.title} className="block-title">
              {block.title}
            </div>
            <div style={styles.subtitle} className="block-subtitle">
              {block.subtitle}
            </div>
          </div>
        </Col>
      )
    })
  }

  render() {
    return (
      <div className="blocks">
        <Group>{this.renderBlockData()}</Group>
      </div>
    )
  }
}

const styles = {
  blockContainer: {
    height: 160,
    width: "100%",
    padding: 24,
  },
  title: {
    color: "#fff",
    fontSize: 48,
    fontWeight: 400,
  },
  subtitle: {
    color: "#fff",
    fontSize: 20,
    lineHeight: 1.1,
    marginTop: 12,
  },
}

export default Blocks
