import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import TopContainer from "../components/TopContainer"
import Footer from "../components/Footer"
import Blocks from "../components/Blocks"
import { Group, Col, Section, CtaButton } from "../components/common"

import marketingPdf from "../pdfs/S-Factor_MarketingAssistant_PD.pdf"
import managerPdf from "../pdfs/S-Factor_OfficeManagerOperationsManager_PD.pdf"
import vpPdf from "../pdfs/S-Factor_VicePresident_Sales-NA.pdf"
import img from "../images/stock/careers1.jpg"

class CareersPage extends Component {
  state = {
    rows: [
      { text: "Vice President, Product – New York / Toronto" },
      { text: "Vice President, Sales – Americas, New York" },
      { text: "Vice President, Sales – Europe, London " },
      { text: "Chief Operating Officer – Toronto" },
      { text: "Chief Marketing Officer – Toronto" },
      { text: "Social Marketing – Toronto" },
      { text: "Data Analysts – Global / Remote " },
      { text: "ESG Analysts – Global / Remote" },
      { text: "Policy Analysts – Global / Remote " },
    ],
  }

  renderDivider() {
    return (
      <div style={{ width: "100%", height: 2, backgroundColor: "#919495" }} />
    )
  }

  renderRows() {
    return this.state.rows.map((row, index) => {
      return (
        <div className="careerRow">
          {this.renderDivider()}
          <div
            style={{
              position: "relative",
              paddingTop: 18,
              paddingBottom: 18,
              fontSize: 22,
            }}
          >
            {row.text}
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <CtaButton
                href={`mailto:careers@thesfactor.co?subject=Application for ${row.text}`}
                target="_blank"
                dark={true}
              >
                Apply{" "}
              </CtaButton>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <>
        <Layout>
          <SEO
            title="Career In ESG | ESG Analyst"
            keywords={["Career In ESG", "ESG Analyst"]}
            description="Looking forward to have a career in ESG? We have openings for an ESG analyst and many more. Contact to apply and grab the opportunity. "
          />
          <TopContainer
            titleLines={[
              "Careers at The S Factor Co. are for people who want to make the world a better place. For people who believe in transparency, accountability and legitimate, unbiased content.",
            ]}
            body="We are always looking for top talent who can help us to better inform the decision makers who depend on our information. Check back here for updates on current available positions."
            backgroundImage={img}
          />

          <Section hasDivider={false}>
            <h3>Upcoming Positions</h3>
            {this.renderRows()}
            {this.renderDivider()}

            <div style={{ height: 32 }} />

            <p style={{ fontSize: 18 }}>
              For consideration, please send cover letter and resume to{" "}
              <a href="mailto:careers@thesfactor.co">careers@thesfactor.co</a>.
            </p>
          </Section>
        </Layout>
      </>
    )
  }
}

export default CareersPage
