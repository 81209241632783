import React, { Component } from "react"

import { CtaButton } from "./common"

class TopContainer extends Component {
  static defaultProps = {
    titleLines: [],
    body: "",
    backgroundImage: null,
    ctaText: null,
    ctaLinkTo: null,
    ctaLinkHref: null,
    ctaLinkTarget: "_self",
    backgroundPosition: "center center",
    isVideo: false,
    mp4: null,
    webm: null,
    wide: false,
  }

  renderTitle = () => {
    return this.props.titleLines.map(line => {
      return (
        <>
          {line}
          <br />
        </>
      )
    })
  }

  renderCta() {
    if (!this.props.ctaText) {
      return null
    }

    if (this.props.ctaLinkTo) {
      return (
        <CtaButton
          linkTo={this.props.ctaLinkTo}
          target={this.props.ctaLinkTarget}
        >
          {this.props.ctaText}
        </CtaButton>
      )
    }

    if (this.props.ctaLinkHref) {
      return (
        <CtaButton
          href={this.props.ctaLinkHref}
          target={this.props.ctaLinkTarget}
        >
          {this.props.ctaText}
        </CtaButton>
      )
    }

    return null
  }

  renderContent() {
    return (
      <div className={this.props.wide ? "wrap wrap-wide" : "wrap"}>
        <div className="accent-divider" />
        <h2>{this.renderTitle()}</h2>
        <p>{this.props.body}</p>
        {this.renderCta()}
      </div>
    )
  }

  render() {
    if (this.props.isVideo && this.props.mp4) {
      return (
        <div className={"top-container top-container--video"}>
          <div className="video-bg">
            <video
              poster={this.props.backgroundImage}
              autoPlay
              playsInline
              muted
            >
              <source src={this.props.mp4} type="video/mp4" />
              {this.props.webm ? (
                <source src={this.props.webm} type="video/webm" />
              ) : null}
            </video>
            <div className="video-cover"></div>
          </div>

          {this.renderContent()}
        </div>
      )
    }
    return (
      <div
        className={
          this.props.backgroundImage
            ? "top-container"
            : "top-container top-container--no-bg"
        }
        style={{
          backgroundColor: "#161c1e",
          backgroundImage: `url(${this.props.backgroundImage})`,
          backgroundPosition: this.props.backgroundPosition,
        }}
      >
        {this.renderContent()}
      </div>
    )
  }
}

export default TopContainer
